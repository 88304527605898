import * as roles from '@/constants/roles';
import { CARDS, INVOICES, LOGIN } from '@/constants/routes';

export default function startPageRedirect({ next, store }) {
  const userRole = store.state.user.role;

  if (userRole === roles.CLIENT) {
    return next({ name: CARDS });
  }

  if (userRole === roles.MANAGEMENT_COMPANY_EMPLOYEE) {
    return next({ name: INVOICES });
  }

  return next({ name: LOGIN });
}
