import { ALL, UNPAID, PAID, RECURRENCE } from '@/constants/entityStatuses';
import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { canAbility } from './middleware';

export default {
  path: '/invoices',
  name: routes.INVOICES_PARENT,
  redirect: { name: routes.INVOICES },
  component: {
    render: h => h('router-view'),
  },
  children: [
    {
      path: '/invoices',
      name: routes.INVOICES,
      component: () => import('@/views/Invoices/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.INVOICES)],
      },
      props: route => ({ status: ALL, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/invoices/unpaid',
      name: routes.INVOICES_UNPAID,
      component: () => import('@/views/Invoices/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.INVOICES)],
      },
      props: route => ({ status: UNPAID, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/invoices/paid',
      name: routes.INVOICES_PAID,
      component: () => import('@/views/Invoices/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.INVOICES)],
      },
      props: route => ({ status: PAID, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/invoices/create',
      name: routes.INVOICES_CREATE,
      component: () => import('@/views/Invoices/Create.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.INVOICES)],
      },
    },
    {
      path: '/invoices/recurrence',
      name: routes.INVOICES_RECURRENCE,
      component: () => import('@/views/Invoices/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.INVOICES)],
      },
      props: route => ({ status: RECURRENCE, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/invoices/recurrence/create',
      name: routes.INVOICES_RECURRENCE_CREATE,
      component: () => import('@/views/Invoices/RecurrenceModify.vue'),
      props: () => ({ isEdit: false }),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.INVOICES)],
      },
    },
    {
      path: '/invoices/recurrence/:id/edit',
      name: routes.INVOICES_RECURRENCE_EDIT,
      component: () => import('@/views/Invoices/RecurrenceModify.vue'),
      props: route => ({ id: Number(route.params.id) || -1, isEdit: true }),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.INVOICES)],
      },
    },
    {
      path: '/invoices/:id',
      name: routes.INVOICES_PAYMENT,
      component: () => import('@/views/Invoices/Payment.vue'),
      meta: {
        middleware: [canAbility([actions.PAY], subjects.INVOICES)],
      },
      props: route => ({
        status: route.query.status,
        id: Number(route.params.id),
        prevPage: Number(route.query.prevPage) || 1,
      }),
    },
  ],
};
