import { PAYMENTS, TASKS, EXTENDED } from '@/constants/dashboardModes';
import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { canAbility } from './middleware';

export default {
  path: '/dashboard',
  name: routes.DASHBOARD,
  redirect: { name: routes.DASHBOARD_PAYMENTS },
  component: {
    render: h => h('router-view'),
  },
  children: [
    {
      path: '/dashboard',
      name: routes.DASHBOARD_PAYMENTS,
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.DASHBOARD)],
      },
      props: () => ({ mode: PAYMENTS }),
    },
    {
      path: '/dashboard/tasks',
      name: routes.DASHBOARD_TASKS,
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.DASHBOARD)],
      },
      props: () => ({ mode: TASKS }),
    },
    {
      path: '/dashboard/extended',
      name: routes.DASHBOARD_EXTENDED,
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.DASHBOARD)],
      },
      props: () => ({ mode: EXTENDED }),
    },
  ],
};
